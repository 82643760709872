/* @media only screen and (max-width: 1200px) {
    .dotted,
    .vdotted,
    .vldotted {
        display: none;
    }
  } */
  
@media only screen and (max-width: 1050px) {
    .dotted,
    .vdotted,
    .vldotted {
        display: none;
    }
}
/* Road Map */

.roadmap_head {
    font-weight: 800;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -0.03em;
    color: var(--blackK);
    padding-top: 80px;
    padding-bottom: 50px;
}

.roadmap_boxes {
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 70px;
    flex-wrap: wrap;
}

.roadmap_box {
    width: 208.09px;
    height: 208.09px;
    /* background: var(--whiteK); */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    padding-left: 28px;
    padding-right: 20px;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.roadmap_box:hover {
    box-shadow: rgba(68, 68, 68, 0.103) 0px 19px 30px, rgba(83, 83, 83, 0.103) 0px 15px 12px;
}

.roadmap_box p {
    padding-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--blackK);
}

.roadmap_box h6 {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--blackK);
}

.roadmap_box .circle {
    margin-top: 17px;
    margin-bottom: 12px;
    height: 35px;
    flex-shrink: 0;
    width: 35px;
    border-radius: 50%;
    background-color: var(--primaryK);
}

.dotted {
    position: absolute;
    width: 65px;
    left: 210px;
    height: 2px;
    border-top: 2px dashed var(--blackK);
    top: 73px;
}

.vdotted {
    position: absolute;
    width: 60px;
    top: 20px;
    left: 15px;
    transform: rotate(-90deg);
    border-top: 2px dashed var(--blackK);
    z-index: 1;
    top: -36px;
    /* z-index: -1; */
}

.vldotted {
    position: absolute;
    width: 60px;
    top: 20px;
    right: 130px;
    transform: rotate(-90deg);
    border-top: 2px dashed var(--blackK);
    /* z-index: 1; */
    top: -36px;
}

.roadmap_box .bcircle {
    margin-top: 17px;
    margin-bottom: 12px;
    height: 35px;
    width: 35px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: transparent;
    border: 5px solid var(--primaryK);
}