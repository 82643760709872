/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'); */
@import url("satoshi.css");

* html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Satoshi-Variable";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.gradient-bg-welcome {
  background-color:#0f0e13;
  background-image: 
    radial-gradient(at 0% 0%, hsla(253,16%,7%,1) 0, transparent 50%), 
    radial-gradient(at 50% 0%, hsla(225,39%,30%,1) 0, transparent 50%), 
    radial-gradient(at 100% 0%, hsla(339,49%,30%,0.5) 0, transparent 50%);
    /* radial-gradient(at 100% 0%, hsla(220,100%,50%,0.3) 0, transparent 50%); */
}

.gradient-bg-services {
  background-color:#0f0e13;
  background-image: 
    radial-gradient(at 0% 0%, hsla(253,16%,7%,1) 0, transparent 50%), 
    radial-gradient(at 50% 100%, hsla(225,39%,25%,1) 0, transparent 50%);
}

.gradient-bg-roadmap {
  background-color:#0f0e13;
  background-image: 
    radial-gradient(at 0% 50%, hsla(253,16%,7%,1) 0, transparent 50%), 
    radial-gradient(at 50% 50%, hsla(225,39%,25%,1) 0, transparent 50%);
}

.gradient-bg-testimonials {
  background-color: #0f0e13;
  background-image: 
    radial-gradient(at 0% 100%, hsla(253,16%,7%,1) 0, transparent 50%), 
    radial-gradient(at 50% 0%, hsla(225,39%,25%,1) 0, transparent 50%);
}

.gradient-bg-footer {
  background-color: #0f0e13;
  background-image: 
    radial-gradient(at 0% 100%, hsla(253,16%,7%,1) 0, transparent 53%), 
    radial-gradient(at 50% 150%, hsla(220,100%,50%,0.3) 0, transparent 50%);
    /* radial-gradient(at 50% 150%, hsla(339,49%,30%,1) 0, transparent 50%); */
}

.blue-glassmorphism {
  background: rgb(39, 51, 89, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* white glassmorphism */
.white-glassmorphism {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.eth-card {
  background-color:#a099ff;
  background-image: 
    radial-gradient(at 83% 67%, rgb(152, 231, 156) 0, transparent 58%), 
    radial-gradient(at 67% 20%, hsla(357,94%,71%,1) 0, transparent 59%), 
    radial-gradient(at 88% 35%, hsla(222,81%,65%,1) 0, transparent 50%), 
    radial-gradient(at 31% 91%, hsla(9,61%,61%,1) 0, transparent 52%), 
    radial-gradient(at 27% 71%, hsla(336,91%,65%,1) 0, transparent 49%), 
    radial-gradient(at 74% 89%, hsla(30,98%,65%,1) 0, transparent 51%), 
    radial-gradient(at 53% 75%, hsla(174,94%,68%,1) 0, transparent 45%);
}

.text-gradient {
  background-color: #fff;
  background-image: radial-gradient(at 4% 36%, hsla(0,0%,100%,1) 0, transparent 53%), radial-gradient(at 100% 60%, rgb(0, 0, 0) 0, transparent 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/***************** COUNTDOWN SECTION *************************/
.countdown-section{
  text-align: center;
  /* margin-top: 300px; */
  /* padding-top: 100px; */
  /* padding-bottom: 100px; */
}

.countdown-section h1{
  color: white;
  /* font-size: 32px; */
  line-height: 44px;
  /* font-weight: 400; */
  margin-bottom: 27px;
  /* margin-top: 60px; */
}
.countdown{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  /* margin: 50px; */
  padding: 50px;
}
.countdown span{
  font-size: 30px;
  margin-top: -55px;
  font-weight: 700;
}
.label{
  display: flex;
  flex-direction: column;
}
.label p{
  color: #88bef5;
  margin: 15px 0;
}
/* .countdown h4{
  font-size: 60px;
  font-weight: 300;
} */
.countdown-format{
  padding: 20px;
  /* margin: 0 20px; */
  /* background-color: #0b1741; */
}
.names{
  display: flex;  
  justify-content: space-between;
  align-items: center;
}
.countdown-section button{
  margin-bottom: 60px;
}
/***************** COUNTDOWN SECTION END *************************/

.logo-container {
  display: flex;
  font-size: 1.8rem;
  font-weight: 800;
  justify-content: flex-start;
  align-items: flex-end;
}
.logo-part1 {
  color: #ffffff;
}
.logo-part2 {
  color: #0055FF;
}

.navbar-close-cnt {
  display: flex;
  justify-content: flex-end;
}

.navbar-close-icon {
  width: 50px;
  height: 34px;
}

.custom-footer-icon {
  margin-right: 5px;
}


:root {
  --primaryK: #0055FF;
  --blackK: #FFFFFF;
  --whiteK: #FFFFFF;
  --greyK: #646464;
  --background: #F1F1F1;
  --pinkBackground: #FAECEB;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
